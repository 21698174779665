import React from 'react';
import './Footer.css';
import logo from '../../Assets/MergeToGrowWhite.svg';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} alt="Logo" className="footer-logo" />
          <p>Trust, Value, and Growth</p>
        </div>
        <div className="footer-column">
          <h3>Services</h3>
          <ul>
            <li><a href="#Taxes">Taxes</a></li>
            <li><a href="#Finances">Finances</a></li>
            <li><a href="#HealthInsurance">Health Insurance</a></li>
            <li><a href="#LifeInsurance">Life Insurance</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Sections</h3>
          <ul>
            <li><a href="#Home">Home</a></li>
            <li><a href="#Services">Services</a></li>
            <li><a href="#Contact">Contact</a></li>
            <li><a href="#Reviews">Reviews</a></li>
            <li><a href="#Newsletter">Newsletter</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact</h3>
          <p>Four Corner, Davenport FL</p>
          <p>(407) 912-9410</p>
          <div className="social-media-links">
            <a href="https://www.facebook.com/mergetogrow" className="facebook-icon"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/mergetogrow" className="instagram-icon"><i className="fab fa-instagram"></i></a>
            <a href="https://wa.me/4079129410" className="whatsapp-icon"><i className="fab fa-whatsapp"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-banner">
        © 2024 Merge to Grow. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;