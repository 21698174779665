import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Services from './components/Services/Services';
import DetailedService from './components/DetailedServices/DetailedServices';
import ContactForm from './components/ContactForm/ContactForm';
import Reviews from './components/Reviews/Reviews';
import Newsletter from './components/Newsletter/Newsletter';
import Footer from './components/Footer/Footer';
function App() {

  return (
    <div className="App">
      <Header />
      <Banner />
      <Services />
      <DetailedService />
      <ContactForm />
      <Reviews />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default App;
