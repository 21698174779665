import React from 'react';
import './Reviews.css';

const reviews = [
  {
    id: 1,
    name: "John Doe",
    service: "Taxes",
    message: "Very professional and thorough. Highly recommended for tax services!"
  },
  {
    id: 2,
    name: "Jane Doe",
    service: "Financial Planning",
    message: "Great advice and planning for my financial future. Thank you!"
  },
  {
    id: 3,
    name: "Jim Doe",
    service: "Life Insurance",
    message: "Helped me choose the right life insurance plan. Excellent service!"
  }
];

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h2>Customer Reviews</h2>
      <div className="reviews-row">
        {reviews.map(review => (
          <div key={review.id} className="review-item">
            <h3>{review.name}</h3>
            <p>Service: {review.service}</p>
            <p>{review.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;