import React from 'react';
import './DetailedServices.css';
import taxesImg from '../../Assets/taxes.png';
import financeImg from '../../Assets/finance.png';
import healthInsuranceImg from '../../Assets/health_insurance.png';
import lifeInsuranceImg from '../../Assets/life_insurance.png';

const servicesDetails = [
  {
    id: 'Taxes',
    title: 'Taxes',
    image: taxesImg,
    imageSide: 'left',
    list: [
      'Personal Taxes',
      'Business Taxes',
      'International Taxes',
      'IRS Representations',
      'Tax Planning',
      'Tax Preparation Courses'
    ],
  },
  {
    id: 'Finances',
    title: 'Finances',
    image: financeImg,
    imageSide: 'right',
    list: [
      'Business Incorporation',
      'Business Accounting',
      'Accounting Reports',
      'QuickBooks Setup',
      'Company Payroll',
      'Sales Tax Reporting'
    ],
  },
  {
    id: 'HealthInsurance',
    title: 'Health Insurance',
    image: healthInsuranceImg,
    imageSide: 'left',
    list: [
      'Marketplace Health Insurance',
      'Medicare Insurance',
      'Accident Insurance',
      'Clinic Membership',
      'Final Expenses Plans'
    ],
  },
  {
    id: 'LifeInsurance',
    title: 'Life Insurance',
    image: lifeInsuranceImg,
    imageSide: 'right',
    list: [
      'Life Insurance',
      'Mortgage Protection',
      'College/University Plan',
      'Executive Bonus Plan',
      'Business Protection Plan',
      'Key Employee Protection Plan',
      'Retirement Plans'
    ],
  },
];

const DetailedService = () => {
  return (
      <div className="services-detail-container">
        {servicesDetails.map((service, index) => (
          <div className="background">
            <div key={index} id={service.id} className={`detailed-service ${service.imageSide}`}>
              {service.imageSide === 'left' && (
                <img src={service.image} alt={service.title} className="detailed-service-image" />
              )}
              <div className="detailed-service-info">
                <h2>{service.title}</h2>
                <ul>
                  {service.list.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              {service.imageSide === 'right' && (
                <img src={service.image} alt={service.title} className="detailed-service-image" />
              )}
            </div>
          </div>
        ))}
      </div>
  );
};

export default DetailedService;
