import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        serviceType: '',
        message: '',
    });

    const isValidEmail = email => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !isValidEmail(formData.email) || !formData.serviceType || !formData.message) {
            alert('Please fill out all fields correctly.');
            return;
        }
        alert('Form is ready to be processed.');
    };

    return (
        <form className="contact-form" id='Contact' onSubmit={handleSubmit}>
            <h3>Contact Us</h3>
            <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label htmlFor="serviceType">Service Type</label>
                <select id="serviceType" name="serviceType" value={formData.serviceType} onChange={handleChange}>
                    <option value="">Select a service</option>
                    <option value="Taxes">Taxes</option>
                    <option value="Finances">Finances</option>
                    <option value="Health Insurance">Health Insurance</option>
                    <option value="Life Insurance">Life Insurance</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default ContactForm;
