import React, { useState } from 'react';
import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    return email.match(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setSubmitted(true);
    setError('');
    alert('Thank you for subscribing to our newsletter!');
    setEmail('');
  };

  return (
    <div className="newsletter-container" id='Newsletter'>
      <h2>Subscribe to Our Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          placeholder="Your email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className={error ? 'error' : ''}
        />
        <button type="submit">Subscribe</button>
        {error && <p className="error-message">{error}</p>}
      </form>
      {submitted && !error && <p>Check your inbox to confirm your subscription!</p>}
    </div>
  );
};

export default Newsletter;
