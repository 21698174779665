import React from 'react';
import './Services.css';
import servicesSummaryImg from '../../Assets/taxes_summary.svg'; 
import financeSummaryImg from '../../Assets/finance_summary.svg'; 
import healthInsuranceSummary from '../../Assets/health_insurance_summary.svg'; 
import lifeInsuranceSummary from '../../Assets/life_insurance_summary.svg'; 

const ServiceItem = ({ image, title, description, link }) => (
  <div className="service-item" id='Services'>
    <img src={image} alt={title} className="service-image" />
    <h3>{title}</h3>
    <p>{description}</p>
    <a href={link} className="service-btn">More Information</a>
  </div>
);

const Services = () => {
  const services = [
    { 
      image: servicesSummaryImg, 
      title: 'Taxes', 
      description: 'Maximize savings with expert tax guidance.', 
      link: '#Taxes'
    },
    { 
      image: financeSummaryImg, 
      title: 'Finances', 
      description: 'Plan and grow your finances.', 
      link: '#Finances'
    },
    { 
      image: healthInsuranceSummary, 
      title: 'Health Insurance', 
      description: 'Secure your health effectively.', 
      link: '#HealthInsurance'
    },
    { 
      image: lifeInsuranceSummary, 
      title: 'Life Insurance', 
      description: 'Protect your loved ones\' future.', 
      link: '#LifeInsurance'
    },
  ];

  return (
    <div className='services-wrapper'>
      <h2>Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <ServiceItem key={index} {...service} />
        ))}
      </div>
    </div> 
  );
};

export default Services;
