import React from 'react';
import './Banner.css';
import logo from '../../Assets/MergeToGrow.png';

const Banner = () => {
  return (
    <div className="banner" id='Home'>
      <img src={logo} alt="Merge To Grow Logo" className="banner-logo" />
      <div className="banner-slogan">
        <p>Trust,</p>
        <p>Value,</p>
        <p>Growth</p>
      </div>
    </div>
  );
};

export default Banner;