import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header>
      <div className="top-banner">
        <div className="contact-info">
          <a href="tel:+14079129410"><i className="fas fa-phone-alt"></i>(407) 912-9410</a>
          <a href="mailto:info@mergetogrow.com"><i className="fas fa-envelope"></i>taxes@mergetogrow.com</a>
        </div>
        <div className="social-media">
          <a href="https://wa.me/4079129410" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
          <a href="https://www.instagram.com/mergetogrow" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.facebook.com/mergetogrow" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
        </div>
      </div>
      <nav className="header-nav">
        <ul className="menu">
          <li><a href="#Home">Home</a></li>
          <li><a href="#Services">Services</a></li>
          <li><a href="#Contact">Contact</a></li>
          <li><a href="#Reviews">Reviews</a></li>
          <li><a href="#Newsletter">Newsletter</a></li>
        </ul>
        <div className="company-title">Merge to Grow</div>
      </nav>
    </header>
  );
}

export default Header;